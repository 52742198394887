/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-04-27 13:46:31
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2023-01-13 08:20:36
 * @FilePath: /fungene-web-master/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
// import ElementUI from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router' //引入路由
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
require('./assets/css/style.css')
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'// nprogress样式文件
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VueI18n); // 国际化
Vue.use(ElementUI);// 饿了么UI组件库
Vue.use(VideoPlayer);// 视频播放器

const navLang = navigator.language;

const i18n = new VueI18n({
    locale: navLang.includes('zh') ? 'zh-CN' : 'en-US',   
    messages: {
      'zh-CN': require('./lang/zh'),   // 中文语言包
      'en-US': require('./lang/en'),   // 英文语言包
      'ja-JP': require('./lang/ja')    // 英文语言包
    }
})

Vue.config.productionTip = false

new Vue({
  i18n,  
  router,
  data: function(){
    return {
       isEnglish:false,
    }
},
  el: '#app',
  render: h => h(App),
}).$mount('#app')

// 进度条
router.beforeEach((to, from , next) => {
  NProgress.start();
  // 让页面回到顶部
  scrollToTop ();
  next();
})

router.afterEach(() => {  
  NProgress.done();
  scrollToTop ();
})

function scrollToTop () {
	setTimeout(() => {
	 document.documentElement.scrollTop = 0;
	 document.body.scrollTop = 0;
	 window.scrollTo(0,0);
	},500);
}