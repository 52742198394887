<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-04-27 13:46:31
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2023-01-05 15:05:28
 * @FilePath: /fungene-web/src/components/foot.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="foot">
    <div class="foot">
      <div class="foot_text">
        {{ $t("m.footText")
        }}<a
          style="color: #777777; font-size: 14px"
          href="https://beian.miit.gov.cn/"
          target="_blank"
          >{{$t("m.record") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footView",

  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
@media screen and (min-width: 1000px) {
  .foot {
    z-index: 999;
    height: 44px;
    background-color: #eeeeee;
    cursor: default;
    overflow-x: hidden;
  }

  .foot_text {
    text-align: center;
    color: #777777;
    font-size: 14px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
    .foot_text > a:hover {
    text-decoration: underline;
  } 
}
@media screen and (max-width: 1000px) {
  .foot {
    height: 44px;
    width: 100%;
    background-color: #eeeeee;
    cursor: default;
  }

  .foot_text {
    text-align: center;
    color: #777777;
    font-size: 14px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
    .foot_text > a:hover {
    text-decoration: underline;
  } 
}
</style>
