<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-04-27 13:46:31
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2023-01-13 09:34:15
 * @FilePath: /fungene-web/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <headers></headers>
    <!-- <router-view></router-view> -->
       <router-view  v-if="isRouterAlive"/>
    <foot></foot>
  </div>
</template>

<script>
import headers from "./components/headers";
import foot from "./components/foot";
export default {
  name: "MainView",
  provide(){
    return{
      reload:this.reload
    }
  },
  components: {
    headers,
    foot,
  },
  data() {
    return {
      isRouterAlive:true,
    
    };
  },
  
  methods: {
    reload (){
       this.isRouterAlive = false
       this.$nextTick(function(){
          this.isRouterAlive = true
       })
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 11rem;
  color: #1d1d1d;
  background-color: #fff;
  position: relative;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  max-width: 100vw; /* 确保body的最大宽度为视口宽度 */
}

/*公共样式*/
body,
ul,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
</style>
