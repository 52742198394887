export const m = {
  //菜单栏
  product: '製品',
  theBrand: 'ブランドストーリー',
  contactUs: 'お問い合わせ',
  languageText:'日本语',
  shop:'購入',
  enText:"English",
  chText:"中文",
  jpText:"日本語",
  aboutFungene: "について Fungene",
  story: "ブランドストーリーです",
  home: "ホームページ",

  //品牌故事
  brand:'FUNGENEは中国の新しいライフスタイルブランドは、持続可能なデザインの概念を遵守し、独創性、技術、環境保護を組み合わせ、人生をよりよく理解する人々に創造的で質の高い高品質の生活体験を提供します。 FUNGENEは「想像力を満足させる人生の美学」を提唱しており、製品のデザインと相互作用を変えることで、より良い生活とより快適な体験を得るのに役立つことを願っています。',
  brand01:'FUNGENEは中国の新しいライフスタイルブランドは、持続可能なデザインの概念を遵守',
  brand02:'し、独創性、技術、環境保護を組み合わせ、人生をよりよく理解する人々に創造的で質',
  brand03:'の高い高品質の生活体験を提供します。 FUNGENEは「想像力を満足させる人生の美学」',
  brand04:'を提唱しており、製品のデザインと相互作用を変えることで、より良い生活とより快適',
  brand05:'な体験を得るのに役立つことを願っています。',

   //联系我们
   contact: 'お問い合わせ',
   company: '広東FUNGENEテクノロジー株式会社',
   address: '会社の住所：広東省佛山市順徳区大梁南国大路3号館1号館',
   holine: 'カスタマーサービスホットライン：4001331518',
   salesHotline: 'セールスホットライン：David +86 13921060980, Sandra +86 13060784883',
   email: '公式メール：service@fungene.cc',
   scantext0: 'すぐにコードをスキャンして',
   scantext1: 'もっと詳しく知る',

    //产品
    mainH5:'スマートソープディスペンサー',
    // mainMarket:'即将上市 敬请期待',
    mainMarket:'From RMB 399',
    mainBook:'購入 >',

    mainTextW0:'すべての変化は熟考した奇跡だ',
    mainTextW1:'これはスマートなソープディスペンサーです。究極の性能、独自',
    mainTextW2:'の設計、優れた製造プロセスを備え、細菌、ウイルスの脅威',
    mainTextW3:'から離れた堅固な健康防御線を構築します',

    mainTextM01:'すべての変化は熟考した奇跡だ',
    mainTextM02:'これはスマートなソープディスペンサーです。究極の性能、独自の設計、優れた製造プロセスを備え、細菌、ウイルスの脅威から離れた堅固な健康防御線を構築します。',

    index01:'長時間スタンバイ',
    index02:'90日間の継続',
    index03:'友好的なコミュニケーション',
    index04:'可愛い表示モード',
    index05:'自感',
    index06:'誤出液防止',
    index07:'静音電機',
    index08:'耐久性に優れた静音モータ',
    index09:'近く自動リアクション',
    index10:'いつでもご利用いただけます',
    index11:'金属製品',
    index12:'より質感のある経験',
    index13:'液不足リマインダー',
    index14:'液量が不足している場合は、交換を指摘していく',
    index15:'うまく設計されています',
    index16:'独創的なデザイン、エレガントで面白い',

    acme01:'何があっても',
    acme02:'最善を尽くす',
    acme03:'継続的に取り組んでいます',
    acme04:'長年を渡して横に歩くだけ',
    acme05:'シンプルなライン、控えめなデザイン、ニュートラルな色が、すっきりとした完璧なイメージを表現',
    acme06:'します絶妙な電気メッキプロセスは、エレガントな視覚効果と繊細で快適なタッチをもたらします',
    acme07:'芸術作品のように美しく細工されており、見た目も意味もあります。',
    acme08:'シンプルなライン、控えめなデザイン、ニュートラルな色が、すっきりとした完璧なイメージを表現します,絶妙な電気メッキプロセスは、エレガントな視覚効果と繊細で快適なタッチをもたらします,芸術作品のように美しく細工されており、見た目も意味もあります。',

    intelligent01:'人工知能',
    intelligent02:'液体の自動誘導',
    intelligent03:'あなたの指先でスマートな生活',
    intelligent04:'接触無し，ただ手を差し伸べる，H5はバクテリアやウイルスの脅威からあなたを守ります',
    intelligent05:'あなたと健全な防衛線を構築する',
    intelligent06:'接触無し',
    intelligent07:'ただ手を差し伸べる',
    intelligent08:'H5はバクテリアやウイルスの脅威からあなたを守ります',

    standby01:'長時間スタンバイ',
    standby02:'90日間の継続',
    standby03:'55000mAh大容量バッテリー+オリジナルのバッテリー管理ソリューション',
    standby04:'航続は2万回に及ぶ， 頻繁に充電する必要はありません',
    standby05:'5000mAh大容量バッテリー',
    standby06:'オリジナルのバッテリー管理ソリューション',
    standby07:'航続は2万回に及ぶ',
    standby08:' 頻繁に充電する必要はありません',
    standby09:'（*FUNGENE Labのデータ、1日30回に基づいて計算）',

    close01:'近接センシング',
    close02:'近づいてください',
    close03:'もっと詳しく感じさせてください',
    close04:'あなたが私の近くにいるとき、私はエネルギーに満ちています。あなたが私の近くにいるとき、あなたは私の心がすべてあなたのおかげで喜びに満ちていることに気付くでしょうさあ、私に近づい',
    close05:'て、あなたを連れてあなたの子供の笑顔とあなたの恋人の口の隅を純粋な手で触れさせて',
    close06:'この世界の優しさと美しさを一緒に感じて保護していく。',
    close07:'あなたが私の近くにいるとき、私はエネルギーに満ちています。あなたが私の近くにいるとき、あなたは私の心がすべてあなたのおかげで喜びに満ちていることに気付くでしょう。さあ、私に近づいて、あなたを連れてあなたの子供の笑顔とあなたの恋人の口の隅を純粋な手で触れさせて、この世界の優しさと美しさを一緒に感じて保護していく。',
  
    interaction01:'自動誘導',
    interaction02:'揺れ異常感応',
    interaction03:'内蔵センサーチップ ，拾ったり振ったりすると敏感，',
    interaction04:'有效防止误出液',
    interaction05:'内蔵センサーチップ ',
    interaction06:'精准判断缺液',
    interaction07:'内蔵センサーチップ ',
    interaction08:'拾ったり振ったりすると敏感',
    interaction09:'液不足誘導',
    interaction10:'機械の状態を把握している',

    color01:'色',
    color02:'エレガントでスタイリッシュ',
    color03:'十分に優れている',
    color04:'夜のように落ち着いた深い黒、上品で深い秋のような古銅色、あなたの専属色にもカスタマイズできます。',
    color05:'深い黒',
    color06:'古銅色',
    color07:'専属色',

    inside01:'内部',
    inside02:'細かいところで風格を見る',
    inside03:'寸分の間に乾坤を定める',
    inside04:'細部は製品の最も強力な表現形式であり、私たちは細部で絶えず求め、より良質な体験をもたらすことを目的としています',

    listen01:'聞いてくれ',
    listen02:'H5誕生の裏にある物語',
    listen03:'よい設計は人生の経歴から生み出すものです。このハンドソープディスペンサは８０代生まれの父親が設計しました。た。コロナが来た時、彼は焦っていました。３歳の息子は反抗期で、とても難しい時期でした。汚い所を触ったり、やってほしいことはやらなかったり、簡単な手洗いもとても困難なことになってしまいました。言うこと聞かないし、脅しつけたり利益で誘ったりするのも効かないでした。最後の最後、子供の泣き声で親が面倒くさいしながら、手を洗っていました。それで、彼は誰でも喜んで手を洗える製品を設計したいと考えていました。',
    listen04:'おさなごこちで奇妙な雰囲気を作りました。簡潔で芸術化的な設計言葉、面白いコミュニケーションと合わせ、このハンドソープディスペンサは単なるハンドソープディスペンサではなく、本当の世界の想像力を具体化したものである、親しい友達みたいに、365日日々付き合って、家族の健康を守ります。',

    parameters01:'基本パラメータ',
    parameters02:'製品型番：Ｈ5',
    parameters03:'定格電圧：4Ｖ', 
    parameters04:'定格パワー：2.5Ｗ',
    parameters05:'製品仕様材質：金属、ABS+PC',
    parameters06:'標準：GB4706.1-2005',

   //底栏
   footText: 'Copyright © 2022 FUNGENE ',
   record:'粤ICP备2020126461号',
  }