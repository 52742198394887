/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-04-27 13:46:31
 * @LastEditors: LeonGor 1015984349@qq.com
 * @LastEditTime: 2023-03-30 15:34:06
 * @FilePath: /fungene-web/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * 路由对象模块
 * */
import Vue from 'vue'
import VueRouter from 'vue-router'


//申明使用插件
Vue.use(VueRouter)

export default  new VueRouter({
	mode: "history",
	routes: [
		{
			path: '/story',
			name: 'story',
			component: () => import('@/components/story'),
		},
		{
			path: '/',
			name: 'home',
			component: () => import('@/components/home'),
			
		},
		{
			path: '/home/:lang',
			name: 'home',
			component: () => import('@/components/home'),
			
		},
		{
			path: '/aboutus',
			name: 'aboutus',
			component: () => import('@/components/aboutus'),
		},
		{
			path: '/mall',
			name: 'mall',
			component: () => import('@/components/mall'),
		},
		{
			path: '/handWash',
			name: 'handWash',
			component: () => import('@/components/handWash'),
		},
		{
			path: '/aromatherapyDiffuser',
			name: 'aromatherapyDiffuser',
			component: () => import('@/components/aromatherapyDiffuser'),
		},
		{
			path: '/problem',
			name: 'problem',
			component: () => import('@/components/problem'),
		},
		{
			path: '/vip',
			name: 'vip',
			component: () => import('@/components/vip'),
		},
		{
			path: '/contect',
			name: 'contect',
			component: () => import('@/components/contect'),
		},
		{
			path: '/video',
			name: 'video',
			component: () => import('@/components/video'),
		},
		{
			path: '/school',
			name: 'school',
			component: () => import('@/components/school'),
		},
		{
			path: '/s5',
			name: 's5',
			component: () => import('@/components/s5'),
		},
		{
			path: '/a8',
			name: 'a8',
			component: () => import('@/components/a8'),
		},
		{
			path: '/h5',
			name: 'h5',
			component: () => import('@/components/h5'),
		},
		{
			path: '/air',
			name: 'air',
			component: () => import('@/components/air'),
		},
		{
			path: '/l6',
			name: 'l6',
			component: () => import('@/components/l6'),
		},
		{
			path: '/media',
			name: 'media',
			component: () => import('@/components/media'),
		},
	],
});
