<template>
	<div id="headers">
		<nav>
			<div class="top_nav">
				<div class="top_nav_menu">
					<ul style="width: 100%;">
						<li class="iconfont ic01" @click="goToHome()">
							<img src="~@/assets/fungene.png" alt="Logo" class="logoImg" />
						</li>
						<li class="nav_font">
							<div class="productDiv">
								<div class="product" @mouseover="showMenu(0)" @mouseleave="hideMenu">
									{{ $t("m.mainH5") }}
								</div>
							</div>
							<div class="downLine" v-show="menuIndex == 0"/>
						</li>
						<li class="nav_font">
							<div class="product" @mouseover="showMenu(1)" @mouseleave="hideMenu">{{ $t("m.xf") }}</div>
							<div class="downLine" v-show="menuIndex == 1"/>
						</li>

						<!-- <li class="nav_font">
							<div class="product" @mouseover="showMenu(2)" @mouseleave="hideMenu">{{ $t("m.noBatter") }}</div>
							<div class="downLine" v-show="menuIndex == 2"/>
						</li> -->

						<li class="nav_font">
							<div class="product" @mouseover="showMenu(3)" @mouseleave="hideMenu">{{ $t("m.oil") }}</div>
							<div class="downLine" v-show="menuIndex == 3"/>
						</li>

						<li class="nav_font">
							<div class="product" @click="goToAboutus">{{ $t("m.aboutFungene") }}</div>
							<div class="downLine" v-show="menuIndex == 4"/>
						</li>

						<li class="nav_font">
							<div class="product" @mouseover="showMenu(4)" @mouseleave="hideMenu">{{ $t("m.contactUs") }}</div>
							<div class="downLine" v-show="menuIndex == 5"/>
						</li>
						
						<li class="nav_font">
							<div class="product" @click="goToMedia">{{ $t("m.media") }}</div>
							<div class="downLine" v-show="menuIndex == 6"/>
						</li>
					</ul>
				</div>
			</div>
			<div class="lineView" />
			
			<div style="margin-left: 90px;position: static;margin-top: -50px;margin-right: 55px" class="dropdown">
				<el-dropdown @command="handleCommand">
					<div class="product">
						{{ $t("m.languageText") }}
					</div>
					<el-dropdown-menu slot="dropdown" placement="bottom">
						<el-dropdown-item command="en">{{ $t("m.enText") }}</el-dropdown-item>
						<el-dropdown-item command="ch">{{ $t("m.chText") }}</el-dropdown-item>
						<!-- <el-dropdown-item command="jp">{{ $t("m.jpText") }}</el-dropdown-item> -->
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</nav>

		<input type="checkbox" id="radio" />
		<div id="nav_bar">
			<ul>
				<li @click="goToHome()">
					<a>{{ $t("m.home") }}</a>
				</li>
				<li @click="goToH5()">
					<a>H5</a>
				</li>
				<li @click="goToA8()">
					<a>A8</a>
				</li>
				<li @click="goToS5()">
					<a>S5</a>
				</li>
				<li @click="goToOil()">
					<a>{{ $t("m.oil") }}</a>
				</li>
				<li @click="goToStory()">
					<a>{{ $t("m.theBrand") }}</a>
				</li>

				<li @click="goToVideo()">
					<a>{{ $t("m.video") }}</a>
				</li>

				<li @click="goToContect()">
					<a>{{ $t("m.contact") }}</a>
				</li>
				<li @click="goToChinese()"><a>中文</a></li>
				<li @click="goToEnglish()"><a>English</a></li>
			</ul>
		</div>
		
		<nav id="Mob">
			<div class="menu">
				<label for="radio">
					<img src="~@/assets/sanheng.png" alt="Logo"
						style="width: 24px;padding-left: 40px;padding-top: 12px;cursor: pointer;padding-bottom: 10px;" />
				</label>
				<img src="~@/assets/fungene.png" alt="Logo"
					style="height: 28px; width: 120px;background-position: center;margin: 0 auto;padding-top: 7px;padding-right: 15%;c" />
			</div>
			<div class="lineView" />
		</nav>

		<!--菜单页-->
		<input type="checkbox" id="menuRadio" />
		<div id="menuDiv" class="menuDIv">
			<div class="leftMenu">
				<div class="lineView" />
				<div style="width: 100%;height: 1vw;" @mouseover="showMenu(-1)" @mouseleave="hideMenu" />
				<ul @mouseover="showMenu(-1)" @mouseleave="hideMenu">
					<li v-for="(model,index) in listData" :key="index" @click="clickMenu(model)"
						@mouseover="showImage = model.iamge" @mouseleave="showImage = ''">
						<i class="el-icon-caret-right" id="arrowIcon"></i>
						<a>{{ model.title }}</a>
					</li>
				</ul>
				<div style="width: 100%;height: 10vw;" @mouseover="showMenu(-1)" @mouseleave="hideMenu" />
			</div>
			<div class="rightMenu" @mouseover="showMenu(-1)" @mouseleave="hideMenu">
				<div class="lineView" />
				<img class="contentImage" v-show="showImage.length > 0" :src="showImage" />
			</div>
		</div>

		
	</div>
</template>

<script>
	export default {
		name: "headersView",
		components: {

		},
		mounted() {

		},
		data() {
			return {
				moveMenuIndex: 0,
				menuIndex: -1,
				productListDivShow: false,
				data: [],
				listData: [],
				showImage: ''
			};
		},
		methods: {
			isChinese() {
				return this.$i18n.locale.includes('zh');
			},
			isEnglish() {
				return this.$i18n.locale.includes('en');
			},
			goToH5Mall() {
				this.productListDivShow = false;
				this.$router.push("/handWash");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToA8Mall() {
				this.productListDivShow = false;
				this.$router.push("/aromatherapyDiffuser");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},

			handleCommand(command) {
				if (command === "en") {
					this.goToEnglish();
				}
				if (command === "ch") {
					this.goToChinese();
				}
				if (command === "jp") {
					this.goToJapan();
				}
			},
			handleAboutCommand(command) {
				if (command === "about") {
					this.goToAboutus();
				}
				if (command === "story") {
					this.goToStory();
				}
			},
			handleServiceCommand(command) {
				if (command === "video") {
					this.goToVideo();
				}
				if (command === "problem") {
					this.goToProblem();
				}
				if (command === "school") {
					this.goToSchool();
				}
				if (command === "vip") {
					this.goToVIP();
				}
				if (command === "contect") {
					this.goToContect();
				}
			},
			goToHome() {
				this.$router.push("/");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToVideo() {
				this.menuIndex = 5;
				this.$router.push("/video");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToProblem() {
				this.$router.push("/problem");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToSchool() {
				this.$router.push("/school");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToContect() {
				this.menuIndex = 5;
				this.$router.push("/contect");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToVIP() {
				this.$router.push("/vip");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToStory() {
				this.$router.push("/story");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToAboutus() {
				this.menuIndex = 4;
				this.$router.push("/aboutus");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToMall() {
				this.$router.push("/mall");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToA8() {
				this.menuIndex = 1;
				this.productListDivShow = false;
				this.$router.push("/a8");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToL6() {
				this.menuIndex = 2;
				this.productListDivShow = false;
				this.$router.push("/l6");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToS5() {
				this.menuIndex = 1;
				this.productListDivShow = false;
				this.$router.push("/s5");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToH5() {
				this.menuIndex = 0;
				this.productListDivShow = false;
				this.$router.push("/h5");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToOil() {
				this.menuIndex = 3;
				this.productListDivShow = false;
				this.$router.push("/air");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToMedia() {
				this.menuIndex = 6;
				this.productListDivShow = false;
				this.$router.push("/media");
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			goToLanguage() {
				if (this.$i18n.locale == "zh-CN") {
					this.$i18n.locale = "en-US";
					this.$root.isEnglish = true;
				} else {
					this.$i18n.locale = "zh-CN";
					this.$root.isEnglish = false;
				}
			},

			goToChinese() {
				this.$i18n.locale = "zh-CN";
				this.$root.isEnglish = false;
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				document.title = this.$t('m.cop');
				this.hideMenu();
			},
			goToEnglish() {
				this.$i18n.locale = "en-US";
				this.$root.isEnglish = true;
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				document.title = this.$t('m.cop');
				this.hideMenu();
			},

			goToJapan() {
				this.$i18n.locale = "ja-JP";
				this.$root.isEnglish = true;
				console.log("isEnglish", this.$root.isEnglish);
				document.getElementById("radio").checked = false;
				document.getElementById("menuRadio").checked = false;
				this.hideMenu();
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
			showMenu(index) {
				document.getElementById("menuRadio").checked = true;
				if (index != -1) {
					let data = [
						[{
								title: 'H3' + this.$i18n.t("m.mainH5"),
								iamge: require("@/images/h3_menu_img.png"),
								methodName: 'goToH5'
							},
							{
								title: 'H5' + this.$i18n.t("m.mainH5"),
								iamge: require("@/images/h5_menu_img.png"),
								methodName: 'goToH5'
							}
						],

						[{
								title: this.$i18n.t("m.a8Title"),
								iamge: require("@/images/a8_menu_img.png"),
								methodName: 'goToA8'
							},
							{
								title: this.$i18n.t("m.s5Title"),
								iamge: require("@/images/s5_menu_img.png"),
								methodName: 'goToS5'
							}
						],

						[{
							title: this.$i18n.t("m.l6"),
							iamge: require("@/images/l6_menu_img.png"),
							methodName: 'goToL6'
						}],

						[{
							title: this.$i18n.t("m.oil"),
							iamge: require("@/images/oil_menu_img.png"),
							methodName: 'goToOil'
						}],

						[{
								title: this.$i18n.t("m.video"),
								iamge: '',
								methodName: 'goToVideo'
							},
							{
								title: this.$i18n.t("m.contact"),
								iamge: '',
								methodName: 'goToContect'
							}
						],
					];
					this.listData = data[index];
				}
			},
			hideMenu() {
				document.getElementById("menuRadio").checked = false;
				// document.getElementById("menuDiv").style.height = '0';
			},
			clickMenu(item) {
				this.$options.methods[item.methodName].bind(this)();
			},
		},
	};
</script>

<style>
	#headers {
		user-select: none;
		z-index: 999;
		position: absolute;
		overflow-x: hidden;
	}
	.downLine{
		width: 100%;
		height: 2px;
		background-color: black;
		margin-top: -11px;
		border-radius: 2px;
	}
	.logoImg {
		height: auto;
		width: 120px;
		background-position: center;
		margin: 0 auto;
		padding-right: 60px;
		padding-left: 15px;
		cursor: pointer;
		padding-top: 5px;
	}

	.product {
		color: black;
		line-height: 3.3;
		font-size: 16px;
		cursor: pointer;
	}

	.productDiv {
		position: relative;
		display: inline-block;
	}

	.lineView {
		width: 100%;
		height: 1px;
		background-color: rgba(0, 0, 0, 0.2);
		position: relative;
	}

	.productListDiv {
		display: flex;
		justify-content: center;
		position: fixed;
		background-color: white;
		width: 100vw;
		height: 190px;
		text-align: center;
		margin-top: 48px;
	}

	#menuRadio:checked+.menuDIv {
		height: 40vw;
	}

	.menuDivText {
		color: black;
		line-height: 3.3;
		cursor: pointer;
		font-size: 16px;
	}

	.menuDIv {
		display: flex;
		justify-content: center;
		position: fixed;
		width: 100vw;
		height: 0vw;
		position: fixed;
		top: 48px;
		overflow: hidden;
		transition: 0.5s;
	}

	.leftMenu {
		width: 50vw;
		height: 150vw;
		background-color: white;
	}

	.leftMenu>ul>li {
		height: 40px;
		display: flex;
		margin-left: 30px;
		align-items: center;
		font-size: 16px;
		font-weight: 700;
		cursor: pointer;
	}

	.leftMenu>ul>li:hover {
		height: 40px;
		display: flex;
		margin-left: 30px;
		align-items: center;
		font-size: 19px;
		font-weight: 1000;
		background-color: rgb(233, 233, 233);
	}

	.rightMenu {
		width: 50vw;
		height: 150vw;
		background-color: white;
		font-weight: 700;
	}

	#arrowIcon {
		display: none;
	}

	.leftMenu>ul>li:hover #arrowIcon {
		display: block;
	}

	.contentImage {
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: 1000px) {

		/*顶部nav&add*/
		#Mob,
		#radio,
		#nav_bar,
		.img_big2,
		.columnM {
			display: none;
		}

		nav {
			width: 100%;
			height: 48px;
			position: fixed;
		}

		.top_nav {
			width: 100%;
		}

		.top_nav_menu {
			height: 48px;
			width: 100%;
			background-color: white;
		}

		.top_nav_menu>ul {
			width: 1000px;
			height: 100%;
			margin: 0 auto;
		}

		.top_nav_menu>ul>li {
			list-style: none;
			float: left;
			margin-left: 3vw;
		}

		.top_nav_menu>ul>.nav_font>a {
			color: black;
			margin-left: 45px;
			margin-right: 45px;
			padding-top: 10px;
			line-height: 3;
			font-size: 16px;
		}

		.top_nav_menu>ul>.nav_font>.el-dropdown>a {
			color: rgb(194, 192, 192);
			padding-left: 45px;
			padding-right: 45px;
			cursor: pointer;
			line-height: 3.3;
			font-size: 16px;
		}

		.top_nav_menu>ul>li.iconfont>a {
			size: 30px;
			color: rgb(189, 181, 181);
			padding-left: 41px;
			padding-right: 41px;
			line-height: 3.6;
			font-weight: 2;
		}

		.top_nav_menu>ul>.nav_font>a:hover,
		.top_nav_menu>ul>li.iconfont>a:hover {
			color: #fff;
		}

		.dropdown {
			position: absolute;
			float: right;
			display: inline-block;
		}

		.dropdown-content {
			display: none;
			position: absolute;
			background-color: #1d1d1d;
			min-width: 80px;
			text-align: center;
			margin-left: -15px;
		}

		.dropdown:hover .dropdown-content {
			display: block;
		}
	}

	@media screen and (max-width: 1000px) {

		nav:nth-child(1),
		.img_small_double,
		.column {
			display: none;
		}

		#Mob {
			display: block;
			width: 100%;
			height: 48px;
			position: fixed;
		}

		#Mob>.menu {
			position: block;
			display: flex;
			box-sizing: border-box;
			width: 100%;
			height: 48px;
			background-color: white;
		}

		#radio {
			display: none;
		}

		#Mob>.menu>a:hover,
		#Mob>.menu>img:hover {
			color: #f5f5f7;
		}

		/*下拉菜单*/
		#nav_bar {
			width: 100%;
			height: 0;
			background-color: white;
			position: fixed;
			top: 48px;
			padding-left: 1px;
			overflow: hidden;
			transition: 0.7s;
		}

		#radio:checked+#nav_bar {
			height: 400px;
		}

		#nav_bar>ul {
			box-sizing: border-box;
			padding: 0 40px;
		}

		#nav_bar>ul>li {
			width: 100%;
			height: 40px;
			line-height: 40px;
			color: black;
			border-bottom: 0.5px solid black;
			cursor: pointer;
		}
	}
</style>